import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#prechladnutie"> &gt; Radca(PRECHLADNUTIE)</a>
            <a
              href="/radca/prechladnutie/domace_sposoby_boja_s_prechladnutim/"
              className="sel"
            >
              {" "}
              &gt; Domáce spôsoby boja s prechladnutím
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/artykuly/GettyImages-1030072086.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Domáce spôsoby boja s prechladnutím</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <b>
                    Čaj s prídavkom zázvoru, cesnaku alebo medu a tradičný vývar
                    sú domáce spôsoby, ktoré nám pomôžu prekonať{" "}
                    <a href="/slovnik_pojmov/#prechladnutie" target="_blank">
                      prechladnutie
                    </a>
                    . Overené metódy odovzdávané z generácie na generáciu môžu
                    nielen zabrániť nákaze, ale dokážu tiež vyhrať boj s
                    ochorením, či zmierniť nádchu, kašeľ a zimnicu.{" "}
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <a href="/slovnik_pojmov/#prechladnutie" target="_blank">
                    Prechladnutie
                  </a>
                  &nbsp;postihne každého z nás minimálne raz ročne, predovšetkým
                  v jesennom a zimnom období. Meniace sa poveternostné
                  podmienky, kratší spánok alebo ochladenie organizmu môžu byť
                  príčinou častých infekcií. Ich hlavné príznaky sú: úporná
                  nádcha, bolesť hlavy, zvýšená telesná teplota, kašeľ a bolesť
                  hrdla. Zmierniť sa dajú domácimi spôsobmi.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Na prvom mieste sa v mnohých domácnostiach nachádza zázvorový,
                  citrónový alebo cesnakový čaj. Používa sa aj mlieko s medom
                  alebo cesnakom, pretože tieto zložky majú veľa prospešných
                  vlastností. Zázvor pôsobí proti zápalu, proti baktériám a v
                  niektorých prípadoch aj proti vírusom. Citrón je zdrojom
                  vitamínu C. Cesnak – ničiteľ baktérií (voláme ho aj prírodné
                  antibiotikum) regeneruje bunky{" "}
                  <a href="/slovnik_pojmov/#imunitny_system" target="_blank">
                    imunitného systému
                  </a>
                  &nbsp; a posilňuje organizmus. Vďaka alicínu, ktorý sa
                  uvoľňuje pri lisovaní strúčikov, účinkuje aj proti plesniam.
                  Med zase zabezpečuje ochranu pred mikroorganizmami, má
                  baktériostatické a baktericídne vlastnosti.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Naše mamy a staré mamy liečili{" "}
                  <a href="/slovnik_pojmov/#prechladnutie" target="_blank">
                    prechladnutie
                  </a>
                  &nbsp;aj cibuľovým sirupom, ktorý obsahuje veľa vitamínov
                  (napr. A, C, PP) s antiseptickým a hojivým účinkom. Domáci
                  sirup doplní nedostatok vitamínov B1, B2 a C a stopových
                  prvkov (napr. zinku, ktorý posilňuje odolnosť voči vírusom,
                  alebo fosforu, ktorý je stavebným kameňom kostí).
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="Vyberte najlepšie riešenie pri chrípke a prechladnutí od znakčy OLYNTH®."
                    src="/web/kcfinder/uploads/images/shutterstock_327254051.jpg"
                    style={{
                      width: "380px",
                      height: "333px",
                      float: "left",
                      margin: "5px 10px 10px 0px",
                      "border-width": "2px",
                      "border-style": "solid"
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  V boji s prechladnutím nesmieme zabudnúť ani na bylinky, ktoré
                  sa odporúčajú najmä v podobe záparov. Je dobré pripraviť si
                  inhalácie napr. z čaju z lipového kvetu (vyvoláva potenie),
                  kvetu a plodov čiernej bazy (majú preukázané protizápalové
                  účinky) alebo rumančeka s tymiánom a rastlinných éterických
                  olejov napr. anízového a eukalyptového oleja (ničia baktérie a
                  zohrievajú organizmus).
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Medzi domáce spôsoby, ako prekonať ochorenie, patrí aj slepačí
                  vývar. Z variaceho sa vývaru sa uvoľňuje cysteín
                  (aminokyselina, ktorá je súčasťou bielkovín) a jeho
                  vdychovanie urýchľuje vykašliavanie hlienov nachádzajúcich sa
                  v pľúcach. Polievka nás zohreje aj posilní a para vystupujúca
                  z taniera uvoľní upchatý nos.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Upchatý nos sťažuje každodenné fungovanie. Opuchnutá{" "}
                  <a href="/slovnik_pojmov/#nosova_sliznica" target="_blank">
                    nosová sliznica
                  </a>
                  &nbsp;zadržiava výtok v nose a blokuje voľné dýchanie nosom,
                  čo napokon spôsobuje bolesť hlavy. V dôsledku nádchy sa nám
                  nedarí zaspať, v noci sa budíme a počas dňa sa preto cítime
                  podráždení a oslabení. V boji s nádchou sa osvedčili lieky,
                  ktoré uvoľňujú upchatý nos, napr. lieky OLYNTH<sup>®</sup>.
                  Liečivom v nosovej roztokovej aerodisperzii{" "}
                  <a href="/lieky/olynth_01/" target="_blank">
                    OLYNTH<sup>®</sup> 0,1 %
                  </a>{" "}
                  alebo{" "}
                  <a href="/lieky/olynth_ha_01/" target="_blank">
                    OLYNTH<sup>®</sup> HA 0,1 %
                  </a>{" "}
                  je{" "}
                  <a href="/slovnik_pojmov/#xylometazolin" target="_blank">
                    xylometazolín
                  </a>
                  , ktorý zužuje cievky nosovej sliznice, čo spôsobuje uvoľnenie
                  dýchania nosom už po 5 až 10 minútach.
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Literatúra:
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Górnicka Jadwiga,{" "}
                    <em>Nie daj się przeziębieniu! Receptury natury</em>, AWM
                    Morex, 2014.{" "}
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a
                  href="/radca/prechladnutie/styri_fazy_vzniku_prechladnutia/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/cztery_fazy_przezi_bienia.png)"}} />
                  <span className="title">Štyri fázy vzniku prechladnutia</span>
                </a>
                <a
                  href="/radca/prechladnutie/ako_sa_ochranit_pred_prechladnutim/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/jak_uchronic_sie_przed_przeziebieniem.png)"}} />
                  <span className="title">
                    Ako sa ochrániť pred prechladnutím?
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
